import request from '@/utils/request'

// 敏感词校验
export function saveActionRecord( data ) {
  return request( {
    url : `/actionRecord/add`,
    method : 'post',
    data : data
  } )
}
